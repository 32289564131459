<template>
  <div id="scrapped">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Scrapped status</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-danger" v-if="scrapped"
            >This vehicle was scrapped on {{ formatDate(data.dateScrapped) }}
          </span>
          <span class="has-text-success" v-else>Not scrapped</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckScrapped',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    scrapped() {
      return this.data.scrapped
    }
  },
  methods: {
    formatDate
  }
}
</script>
